import React, { useState } from "react";
import { StaticImage } from 'gatsby-plugin-image';
import NewsletterSubscribe from '~/components/molecules/NewsletterSubscribe';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import Image from "~/components/atoms/Image";

export default function Newsletter({heading = 'Subscribe today', content = 'Sign up to City Relay and be the first to hear about new content and upcoming events.', key, index, ...props}) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const section = props?.newsletter


  return (
    <section className="my-20 md:my-36">
      <div className="container">
        <FadeInWhenVisible className="grid grid-cols-12 items-center bg-navy py-20 md:py-10 text-white">
          <div className="col-span-12 lg:col-span-4 lg:col-start-2 mb-12 lg:mb-0 px-10 lg:px-0">
            {section?.illustration ? (
              <Image data={section.illustration} className="w-full max-w-xs md:max-w-none md:w-80 lg:mx-auto block" alt="CityRelay" />
            ) : (
              <StaticImage src={'../../assets/images/newsletter.png'} className="w-full max-w-xs md:max-w-none md:w-80 lg:mx-auto block" alt="CityRelay" />
            )}

          </div>
          <div className="col-span-12 lg:col-span-7 px-10 lg:pb-20 lg:p-20 lg:px-24 flex flex-col justify-center items-start">
            <FadeInWhenVisible>
              <h4 className="text-h4 mb-5">{!isSubmitted ? (section?.heading ? section.heading : heading) : 'Thank you for joining our newsletter'}</h4>
            </FadeInWhenVisible>

            {!isSubmitted && (
              <>
                <FadeInWhenVisible>
                  <p className="text-opacity-80 text-white mb-5">{section?.content ? section?.content : content}</p>
                </FadeInWhenVisible>

                <div className={`w-full transition-opacity duration-200`}>
                  <NewsletterSubscribe setIsSubmitted={setIsSubmitted} />
                </div>
              </>
            )}

          </div>
        </FadeInWhenVisible>
      </div>
    </section>
  );
}
